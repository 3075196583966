import httpHelper from "@/helpers/httpHelper"
import { DataT, FormT } from "@/types"

export default {
  lan: (culture: string) =>
    httpHelper.get<DataT.LanPackage>({
      host: window.location.origin,
      api: `/static/languages/${culture}.json`,
    }),
  verifyEmail: (email: string) => httpHelper.post<DataT.base>(window.globalConfig.apiBase + `/api/base/verifyEmail`, {}, { email }),
  changePassword: (data: FormT.FormPayload.passwordForm) => httpHelper.post<DataT.base>(window.globalConfig.apiBase + `/api/user/password`, {}, data),
  logout: () => httpHelper.post<DataT.base>(window.globalConfig.apiBase + `/api/user/logout`),
  getStatistics: (pageSize: number = 10, page: number = 0, desc = true) => httpHelper.post<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/visit/check`, {}, { page, pageSize, desc }),
  exportAllStatistics: (day: number) => window.globalConfig.apiBase + `/api/visit/export?rd=${day}&token=${localStorage.getItem("token")}`,
  fileFind: (data: Record<string, any>) => httpHelper.post<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/file/breakpointFind`, {}, data),
  uploadFinish: (data: { md5: string, fileName: string, category: string }) => httpHelper.post(window.globalConfig.apiBase + `/api/file/breakpointUploadFinish`, {}, data),
  upload: (formData: FormData) => httpHelper.post<DataT.base>({
    api: window.globalConfig.apiBase + `/api/file/breakpointUpload`,
    data: formData,
  }),
  verifyLink: (data: Record<string, any>) => httpHelper.post<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/base/verifyLink`, {}, data),
}