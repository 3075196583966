import httpHelper from "@/helpers/httpHelper"
import { DataT } from "@/types"

export default {
    login: () => httpHelper.get<{url:string}>(window.globalConfig.apiBase + `/login`),
    logout: () => httpHelper.delete(window.globalConfig.apiBase + `/logout`),
    getAEList: ({
        by = "created_at",
        order = "desc",
        page = 0,
        size = 10
    }: {
        by?: string,
        order?: "desc" | "asc",
        page?: number
        size?: number
    }) => httpHelper.get<any>(window.globalConfig.apiBase + `/project/list?by=${by}&order=${order}&page=${page}&size=${size}`),
    getUserInfo: () => httpHelper.get<DataT.base & { data: any }>(window.globalConfig.apiBase + `/user`),
    exportAll: () => httpHelper.get<DataT.base & { data: any }>(window.globalConfig.apiBase + `/project/export`),
    createAe: (data: any) => httpHelper.get<DataT.base & { data: any }>(window.globalConfig.apiBase + `/project/generate?customer_name=${data.customer_name}&domain=${data.domain}`)
}