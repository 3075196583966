
import login from "./login"
import partner from "./partner"
import system from "./system"
import user from "./user"
import market from "./market"
import training from "./training"
import notice from "./notice"
import visitor from "./visitor"
import agent from "./agent"
import ae from "./ae"


export default {
	system,
	user,
	partner,
	login,
	market,
	training,
	notice,
	visitor,
	agent,
	ae
}
