import { enumVal } from "@/enums"
import httpHelper from "@/helpers/httpHelper"
import { DataT, StoreT, FormT } from "@/types"

export default {
  // 获取partner列表
  getList: (pageSize: number, page: number, status: enumVal.PartnerStatusVal, orderKey = "status", desc = true) => httpHelper.post<DataT.base & { data: DataT.User }>(window.globalConfig.apiBase +  `/api/partner/list`, {}, { status, page: page, pageSize, orderKey, desc }),
  getRecentlyUpdate: (pageSize: number = 10, page: number = 1, orderKey = "created_at", desc = true) => httpHelper.post<DataT.base & { data: { list: StoreT.recentlyUpdateItem[] } }>(window.globalConfig.apiBase +  `/api/visitor/market/recentlyUpdate`, {}, { page: page, pageSize, orderKey, desc }),
  // 操作partner状态
  partnerAction: (uuid: string, status: enumVal.PartnerStatusActionVal) => httpHelper.put(window.globalConfig.apiBase +  `/api/partner/action`, {}, { uuid, status }),
  statistics: () => httpHelper.get<DataT.PartnerStatistics>(window.globalConfig.apiBase +  `/api/partner/statistics`),
  addSale: (data: FormT.FormPayload.adminSalesInformationUploadForm) => httpHelper.post<DataT.base>(window.globalConfig.apiBase +  `/api/sale/add`, {}, data),
  getSaleList: (pageSize: number = 10, page: number = 1, region: string, desc = true) => httpHelper.post<DataT.base & { data: Record<string, any> }>(window.globalConfig.apiBase +  `/api/sale/list`, {}, { region, page: page, pageSize, desc }),
  deleteSale: (id: number) => httpHelper.delete(window.globalConfig.apiBase +  `/api/sale/delete`, {}, { id }),
  activeRegion: () => httpHelper.get<DataT.base & { data: Record<string, number> }>(window.globalConfig.apiBase +  `/api/partner/activeRegion`),
  batchDelete: (name: string, ids: number[]) => httpHelper.delete(window.globalConfig.apiBase +  `/api/${name}/batchDelete`, {}, { ids }),
  deleteRejectedPartner: (uuid: string) => httpHelper.delete(window.globalConfig.apiBase + "/api/partner/delete",{},{ uuid })
}