import { message } from "ant-design-vue"
import Request from "./http/Request"
import { getErrorMsg } from "./tools/index"
import lanSetting from "./lanHelper"

export default new Request({
	host: window.location.origin,
	interceptAfter({ result, type }) {
		if (type === "error") {
			console.error(result)

			if(result.status === 403) {
				window.location.href = "/#login"
				message.error({
					content: result.response.msg
				})
			} else {
				message.error({
					content: lanSetting.get("public", "error")
				})
			}
		}
		// if (type === "success" && result.code !== 0) {
		// 	if (result.data?.reload) {
		// 		window.localStorage.removeItem("token")
		// 		// window.location.reload()
		// 	}
		// }
	},
	// headers: {
	// 	"Accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7"
	// }
})


export function handleErrMeg(msg: string | undefined) {
	if (msg) {
		message.error(getErrorMsg(msg))
	}
}
