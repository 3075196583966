import api from "@/api"
import { enumVal } from "@/enums"
import { DataT } from "@/types"

const lanSetting = {
	lanPackage: null as null | DataT.LanPackage,
	init: async (culture: enumVal.CultureVal) => {
		const lan = await api.system
			.lan(culture)
			.then((res) => res)
			.catch((error) => null)
		lanSetting.lanPackage = lan
	},
	get: (category: string, item: string, data?: Record<string, string>) => {
		if (lanSetting.lanPackage === null) {
			console.error("lanPackage is not initialized")
			return ""
		}
		const _t = (() => {
			try {
				return lanSetting.lanPackage[category][item]
			} catch (error) {
				console.error(`Lan ${category} ${item}  is not found`)
				return ""
			}
		})()
		if (data) {
			return _t.replace(/(<%=.*(?=%>)%>)/gim, (r1) => {
				const _i = /<%=(?<index>.*(?=%>))/.exec(r1)
				if (_i) {
					return _i.groups?.index ? data[_i.groups?.index] : ""
				}
				return ""
			})
		}
		return _t
	},
}
export default lanSetting
