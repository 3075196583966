import lanSetting from "../lanHelper";
import { FormT } from "@/types";
import enums, { enumVal, FormType } from "@/enums";
import { message, Modal } from "ant-design-vue";
import { handleErrMeg } from "../httpHelper";
import { h } from "vue";
import { VueNode } from "ant-design-vue/lib/_util/type";

export const fromEntriesEnum = (_enum: any) => {
  return Object.fromEntries(Object.entries(_enum))
}

function allSome(value: string) {
  //不能连续字符（如123、321、abc、cba）连续3位或3位以上
  if (value.length >= 3) {
    let arr = value.split('')
    let flag = true;
    for (let i = 1; i < arr.length - 1; i++) {
      let firstIndex = arr[i - 1].charCodeAt(0)
      let secondIndex = arr[i].charCodeAt(0)
      let thirdIndex = arr[i + 1].charCodeAt(0)
      if ((thirdIndex - secondIndex === 1) && (secondIndex - firstIndex === 1) || (thirdIndex - secondIndex === 0) && (secondIndex - firstIndex === 0) || (thirdIndex - secondIndex === -1) && (secondIndex - firstIndex === -1)) {
        flag = true;
        break
      } else {
        flag = false;
      }
    }
    return flag;
  }
}



export const renderErrorMessage = (message: string) => {
  return <span style={{ color: "#ff4d4f", display: "flex" }}><svg class="icon" aria-hidden="true" style={{ fontSize: "1.4rem", marginRight: "0.5rem" }}> <use xlinkHref="#icon-Shapex"></use> </svg> {message}</span>
}

export const validatorTool = (rule: any, message: string) => {
  if (rule) {
    return Promise.reject(renderErrorMessage(message));
  } else {
    return Promise.resolve();
  }
}

export const _customValidators = {
  email: () => {
    return {
      validator: (rule: any, value: string) => {
        return validatorTool((!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value) && value !== ""), lanSetting.get("public", "emailError"))
      }
    }
  },
  passwordLength: () => {
    return {
      validator: (rule: any, value: string) => {
        return validatorTool(!(value.length >= 8 && value.length < 16), lanSetting.get("public", "pswLength"))
      }
    }
  },
  passwordRegexTrue: () => {
    return {
      validator: (rule: any, value: string) => {
        return validatorTool(!/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!;|<>`&!*(~^)#?:"/$=\\]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!;|<>`&!*(~^)#?:"/$=\\]+$)[a-zA-Z0-9\\W_!;|<>`&!*(~^)#?:"/$=\\]+$/.test(value), lanSetting.get("public", "pwdRegexTrue"))
      }
    }
  },
  passwordIncludeAccount: () => {
    return {
      validator: (rule: any, value: string) => {
        return validatorTool(value.indexOf("administrator") > -1, lanSetting.get("public", "includeAccount"))
      }
    }
  },
  passwordContinuous: () => {
    return {
      validator: (rule: any, value: string) => {
        return validatorTool(allSome(value), lanSetting.get("public", "continuous"))
      }
    }
  },
  checkboxRequired: () => {
    return {
      validator: (rule: any, value: string) => {
        return validatorTool(!value, lanSetting.get("public", "requiredCheckBox"))
      }
    }
  },
  sameTest: (formValue: Record<string, any>, dataIndex: string) => {
    return {
      validator: (rule: any, value: string) => {
        if (formValue[dataIndex] !== value) {
          return Promise.reject(renderErrorMessage(lanSetting.get("public", "notMatchPassword")))
        } else {
          return Promise.resolve()
        }
      }
    }
  },
  emptyFile: () => {
    return {
      validator: (rule: any, value: Record<any, any>) => {
        return validatorTool(value[0] && value[0].size === 0, lanSetting.get("public", "emptyFileError"))
      }
    }
  },
  fileType: (fileType: string) => {
    return {
      validator: (rule: any, value: Record<any, any>) => {
        const type = value[0] && value[0].name.split(".")[value[0].name.split(".").length - 1]
        return validatorTool(type !== fileType, lanSetting.get("public", "fileTypeErrorApk"))
      }
    }
  },
  onlyNumberLetters: () => {
    return {
      validator: (rule: any, value: string) => {
        return validatorTool(!/^[0-9a-zA-Z]*$/.test(value), lanSetting.get("formConfig", "signatureTips"))
      }
    }
  },
  onlyNumberPointLetters: () => {
    return {
      validator: (rule: any, value: string) => {
        return validatorTool(!/^[0-9a-zA-Z]+[0-9a-zA-Z \.]*$/.test(value), lanSetting.get("public", "onlyNumberPointLettersTips"))
      }
    }
  },
  onlyNumberPointCommaLetters: () => {
    return {
      validator: (rule: any, value: string) => {
        return validatorTool(!/^[0-9a-zA-Z]+[0-9a-zA-Z \.,，]*$/.test(value), lanSetting.get("public", "onlyNumberPointCommaLetters"))
      }
    }
  },
}

export const baseRuleValidators = {
  min: (minLength: number, trigger?: FormT.triggerType) => {
    return {
      min: minLength,
      trigger: trigger,
      message: (renderErrorMessage(lanSetting.get("public", "min", { min: String(minLength) })))
    }
  },
  max: (maxLength: number, trigger?: FormT.triggerType) => {
    return {
      max: maxLength,
      trigger: trigger,
      message: (renderErrorMessage(lanSetting.get("public", "max", { max: String(maxLength) })))
    }
  },
  len: (length: number, trigger?: FormT.triggerType) => { //固定长度
    return {
      len: length,
      trigger: trigger,
      message: (renderErrorMessage(lanSetting.get("public", "exactLength", { exactLength: String(length) })))
    }
  },
  whitespace: (whitespace?: boolean, trigger?: FormT.triggerType) => {  //是否将只包含空格的必填字段作为错误处理
    return {
      whitespace: whitespace,
      trigger: trigger,
      message: (renderErrorMessage(lanSetting.get("public", "whitespace")))
    }
  }
}

export const customValidators = {
  email: _customValidators.email(),
  passwordLength: _customValidators.passwordLength(),
  passwordRegexTrue: _customValidators.passwordRegexTrue(),
  passwordIncludeAccount: _customValidators.passwordIncludeAccount(),
  passwordContinuous: _customValidators.passwordContinuous(),
  checkboxRequired: _customValidators.checkboxRequired(),
  samePassword: _customValidators.sameTest,
  emptyFile: _customValidators.emptyFile(),
  fileType: _customValidators.fileType,
  onlyNumberLetters: _customValidators.onlyNumberLetters(),
  onlyNumberPointLetters: _customValidators.onlyNumberPointLetters(),
  onlyNumberPointCommaLetters: _customValidators.onlyNumberPointCommaLetters()
}





export const getFormDefaultState = (formConfig: FormT.formConfigItem[]) => {
  let state: FormT.FormState = {}

  formConfig && formConfig!.map(_config => {
    switch (_config.dataType) {
      case FormType.input:
      case FormType.password:
      case FormType.textArea:
      case FormType.captcha:
        state[_config.dataIndex] = ""
        break;
      case FormType.enum:
        state[_config.dataIndex] = _config.defaultValue ? _config.defaultValue : undefined
        break;
      case FormType.file:
        state[_config.dataIndex] = []
        break;
      case FormType.checkbox:
        state[_config.dataIndex] = false
    }
    return _config
  })

  return state
}


export const getDefaultUserType = (userType?: enumVal.UserTypesVal) => {
  if (!localStorage.getItem("token")) {
    return enums.UserTypes.null
  }
  //  else {
  //   if (userType === enums.UserTypes.admin) {
  //     return enums.UserTypes.admin
  //   }
  //   if (userType === enums.UserTypes.partner) {
  //     return enums.UserTypes.partner
  //   }

  //   return enums.UserTypes.null
  // }
}




export const actionModal = (params: { title?: VueNode, content?: VueNode, okText?: string, apiFunction: (() => Promise<unknown>) | undefined, callback?: () => void }) => {
  const hTitle = h(
    'div',
    {
      style: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontWeight: "bold"
      }
    },
    [
      <svg class="icon" aria-hidden="true" style={{ fontSize: "2rem", marginRight: "1rem" }}> <use xlinkHref="#icon-Shapex"></use> </svg>,
      params.title
    ]
  )

  Modal.confirm({
    title: hTitle,
    okText: params.okText || lanSetting.get("public", "confirm"),
    cancelText: lanSetting.get("public", "cancel"),
    icon: false,
    content: params.content,
    width: 450,
    onOk() {
      return new Promise((resolve, reject) => {
        params.apiFunction && params.apiFunction().then((res: any) => {
          if (res.code === 0) {
            resolve(true)
            if (params.callback) {
              params.callback()
            }
          } else {
            handleErrMeg(res.msg)
          }

        }, () => {
          resolve(true)
        })

      }).catch(() => console.log('Oops errors!'));
    },
    onCancel() { },
  })
}


export const downloadFileByStream = (res: Record<string, any>, fileName: string) => {
  const filestream = res.response;  // 返回的文件流
  const blob = new Blob([filestream], { type: filestream.type });
  const a = document.createElement('a');
  const href = window.URL.createObjectURL(blob); // 创建下载连接
  a.href = href;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a); // 下载完移除元素
  window.URL.revokeObjectURL(href); // 释放掉blob对象
}

export const downloadFileByUrl = (href: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = href;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a); // 下载完移除元素
}

export const downloadFileByFetch = (api: string) => {
  // 发起HTTP请求
  fetch(api)
    .then(response => {
      // 检查响应是否成功
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // 获取文件名
      const disposition = response.headers.get('content-disposition') || "";
      const fileName = disposition.split(';')[1].split('=')[1].replace(/"/g, '');
      // 返回响应的二进制数据
      return response.blob().then(blob => {
        // 创建一个URL
        const url = window.URL.createObjectURL(blob);
        // 创建一个<a>元素
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName; // 设置下载文件名
        document.body.appendChild(a);
        a.click();
        // 释放URL对象
        window.URL.revokeObjectURL(url);
      });
    })
    .catch(error => {
      if (error.response && error.response.status === 403) {
        window.location.href = "/#login"
        message.error({
          content: error.response.msg
        })
      } else {
        message.error({
          content: lanSetting.get("public", "error")
        })
      }
    });
}


export const getErrorMsg = (msg: string) => {
  switch (msg) {
    //密码错误、账号不存在、账户被禁止登录
    case "User is prohibited from logging in": {
      return lanSetting.get("public", "errUserLoginProhibition")
    }
    case "Captcha error": {
      return lanSetting.get("public", "errVerificationCode")
    }
    case "record not found": {
      return lanSetting.get("public", "recordNotFound")
    }
    case "The modification failed, and the original password does not match the current account": {
      return lanSetting.get("public", "errorChangePassword")
    }
    case "The username or password is incorrect": {
      return lanSetting.get("public", "errorUsernamePassword")
    }
    case "this account has not been approved": {
      return lanSetting.get("public", "errAccountNotApproved")
    }

    default: {
      return msg
    }
  }
}


export const debounce = (func: any, delay: number) => {
  let timerId: NodeJS.Timeout

  return function (...arg: any[]) {
    const context = this;
    const args = arg;

    clearTimeout(timerId)

    timerId = setTimeout(() => {
      func.apply(context, args);
    }, delay)
  }
}

const { log, error, warn } = console
 
export const WebLogger = {
    log: log.bind(console),
    error: error.bind(console),
    warn: warn.bind(console)
}