import { enumVal } from "@/enums"
import httpHelper from "@/helpers/httpHelper"
import { DataT } from "@/types"

export default {
    // 获取partner列表
    getList: (pageSize: number, page: number, category: string, desc = true) => httpHelper.post<DataT.base & { data: DataT.User }>(window.globalConfig.apiBase +  `/api/training/list`, {}, { category, page: page, pageSize, desc }),
    upload: (name: string, file: Blob, category: enumVal.TrainingCategoryVal) => httpHelper.post<DataT.base>(window.globalConfig.apiBase +  `/api/training/upload`, {}, {
        name,
        category,
        file
    }),
    delete: (id: number) => httpHelper.delete(window.globalConfig.apiBase +  `/api/training/delete`, {}, { id })
}