import httpHelper from "@/helpers/httpHelper"
import { DataT } from "@/types"

export default {
    // 获取partner列表
    getList: (pageSize: number, page: number, category: string, desc = true) => httpHelper.post<DataT.base & { data: DataT.User }>(window.globalConfig.apiBase + `/api/market/list`, {}, { category, page: page, pageSize, desc }),
    // upload: (name: string, file: Blob, category: enumVal.CategoryVal) => httpHelper.post<DataT.base>({
    //     api: window.globalConfig.apiBase + `/api/market/upload`,
    //     data: { name, file, category },
    // }),
    upload: (formData:FormData) => httpHelper.post<DataT.base>({
        api: window.globalConfig.apiBase + `/api/file/breakpointUpload`,
        data: formData,
    }),
    delete: (id: number) => httpHelper.delete(window.globalConfig.apiBase + `/api/market/delete`, {}, { id }),
    downlaod: (id: number) => window.globalConfig.apiBase + `/api/visitor/market/download?id=${id}&token=${localStorage.getItem("token")}`
}