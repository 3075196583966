import httpHelper from "@/helpers/httpHelper"
import { DataT } from "@/types"

export default {
  getAgentList: (data: Record<string, any>) => httpHelper.post<DataT.Captcha>(window.globalConfig.apiBase + `/api/agent/list`, {}, data),
  getPartnerAgentList: (data: Record<string, any>) => httpHelper.post<DataT.Captcha>(window.globalConfig.apiBase + `/api/visitor/agent/list`, {}, data),
  getAgentManufacturer: () => httpHelper.get<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/agent/manufacturer`),
  getPartnerAgentManufacturer: () => httpHelper.get<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/visitor/agent/manufacturer`),
  fileFind: (data: Record<string, any>) => httpHelper.post<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/agent/breakpointFind`, {}, data),
  upload: (formData: FormData) => httpHelper.post<DataT.base>({
    api: window.globalConfig.apiBase + `/api/agent/breakpointUpload`,
    data: formData,
  }),
  uploadFinish: (data: { md5: string, fileName: string }) => httpHelper.post<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/agent/breakpointUploadFinish`, {}, data),
  createAgent: (data: Record<string, any>) => httpHelper.post<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/agent/new`, {}, data),
  deleteAgent: (ids: number[]) => httpHelper.delete<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/agent/delete`, {}, {ids}),
  upgrade: (data: Record<string, any>) => httpHelper.put<DataT.base & { data: any }>(window.globalConfig.apiBase + `/api/agent/upgrade`, {}, data),
  downlaod: (id: number) => window.globalConfig.apiBase + `/api/visitor/agent/download?id=${id}&token=${localStorage.getItem("token")}`,
  adminDownlaod: (id: number) => window.globalConfig.apiBase + `/api/agent/download?id=${id}&token=${localStorage.getItem("token")}`,
  cancel: (fileId: number) => httpHelper.post<DataT.base>(window.globalConfig.apiBase + `/api/agent/cancel`, {}, { fileId }),
}