export enum UserTypes {
	partner = "partner",
	admin = "admin",
	null = "null",
	ae = "ae"
}
export enum Culture {
	en = "en-US",
	// es = "es-MX",
	// fr = "fr-FR",
	// ja = "ja-JP",
	// ko = "ko-KR",
	// pt = "pt-BR",
	// ru = "ru-RU",
	zh = "zh-CN",
}
export enum PartnerStatus {
	Pending = 3,
	Active = 1,
	Disabled = 2,
	Rejected = 4
}

export enum FormType {
	input = "Input",
	password = "password",
	textArea = "textArea",
	custom = "custom",
	enum = "enum",
	captcha = "captcha",
	file = "file",
	checkbox = "checkbox",
	selectInput = "selectInput"
}

export enum ButtonType {
	link = "link",
	default = "default",
	primary = "primary",
	ghost = "ghost",
	dashed = "dashed",
	text = "text",
}

export enum FileType {
	pdf = "pdf",
	image = "image",
	video = "video",
	ppt = "ppt",
	pptx = "pptx",
	docx = "docx",
	txt = "txt",
	xlsx = "xlsx",
	jpg = "jpg",
	jpeg = "jpeg",
	png = "png",
	mp4 = "mp4"
}

export enum TableHandler {
	Upload = "upload",
	BulkDelete = "bulkDelete",
	BulkDownload = "bulkDownload",
	ExportAll = "ExportAll",
	Create = "create"
}

export enum Region {
	RegionAsia = "Asia",
	RegionEurope = "Europe",
	RegionNorthAmerica = "NorthAmerica",
	RegionSouthAmerica = "SouthAmerica",
	RegionAustralia = "Australia",
	RegionAfrica = "Africa"
}

export enum Category {
	CategoryProductDecks = "productDecks",
	CategoryCompanyDeck = "companyDeck",
	CategoryCaseStudy = "caseStudy",
	CategoryWhitepapers = "whitepapers",
	CategoryUserGuide = "userGuide",
	CategoryVideos = "videos",
	CategoryOthers = "others"
}

export enum TrainingCategory {
	TrainingCategoryDocumentations = "documentations",
	TrainingCategoryTrainingvideos = "trainingvideos",
	TrainingCategoryLivetrainingrecords = "livetrainingrecords"
}

export enum agentCategory {
	agentCategoryAgent = "Agent",
	agentCategoryRemoteControl = "RemoteControl",
	agentCategorySettings = "Settings",
	agentCategoryBrowser = "Browser",
	agentCategoryAgentOnly = "AgentOnly",
	agentCategoryServiceOnly = "ServiceOnly"
}

export enum PartnerStatusAction {
	reject = 4,
	approve = 1,
	reactivate = 3,
	disable = 2
}

export enum Tab {
	detail = "detail",
	create = "create"
}

export enum FileRealType {
	apk = "apk"
}


export namespace enumVal {
	export type UserTypesVal = keyof Record<UserTypes, string>
	export type CultureVal = keyof Record<Culture, string>
	export type PartnerStatusVal = keyof Record<PartnerStatus, string>
	export type ButtonTypeVal = keyof Record<ButtonType, string>
	export type FileTypeVal = keyof Record<FileType, string>
	export type FormTypeVal = keyof Record<FormType, string>
	export type TableHandlerVal = keyof Record<TableHandler, string>
	export type RegionVal =  keyof Record<Region, string>
	export type CategoryVal = keyof Record<Category, string>
	export type TrainingCategoryVal = keyof Record<TrainingCategory, string>
	export type PartnerStatusActionVal = keyof Record<PartnerStatusAction, string>
	export type TabVal = keyof Record<Tab, string>
}

export default {
	UserTypes,
	Culture,
	PartnerStatus,
	FormType,
	ButtonType,
	FileType,
	TableHandler,
	Category,
	Region,
	TrainingCategory,
	agentCategory
}
