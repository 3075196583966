import httpHelper from "@/helpers/httpHelper"
import { DataT } from "@/types"

export default {
    downloaded: () => httpHelper.get<DataT.base & { data: { total: number } }>(window.globalConfig.apiBase +  `/api/visitor/home/downloaded`),
    fileResources: () => httpHelper.get<DataT.base & { data: { total: number } }>(window.globalConfig.apiBase +  `/api/visitor/home/fileResources`),
    getMarketList: (pageSize: number, page: number, category: string, desc = true) => httpHelper.post<DataT.base & { data: DataT.User }>(window.globalConfig.apiBase +  `/api/visitor/market/list`, {}, { category, page: page, pageSize, desc }),
    getVisitorTrainingList: (pageSize: number, page: number, category: string, desc = true) => httpHelper.post<DataT.base & { data: DataT.User }>(window.globalConfig.apiBase +  `/api/visitor/training/list`, {}, { category, page: page, pageSize, desc }),
    downlaod: (id: number) => window.globalConfig.apiBase +  `/api/visitor/training/download?id=${id}&token=${localStorage.getItem("token")}`,
    changeProfile: (data: any) => httpHelper.put<DataT.base>(window.globalConfig.apiBase +  `/api/visitor/user/profile`, {}, data)
}